







import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import EventTypePoint from '@/modules/common/components/ui-kit/event-type-point.vue';
import Day from '@/modules/common/types/day.type';
import isDayType from '@/modules/common/component-validators/is-date.validator';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';

@Component({
    components: {
        EventTypePoint,
    },
})
export default class EventTypesPoints extends Vue {
    @Inject(EventsManagerServiceS) private eventsManagerService!: EventsManagerService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;

    @Prop({
        type: Number,
        required: true,
        validator: isDayType,
    })
    public day!: Day;

    @Prop({
        type: String,
        required: false,
        default: '',
    })
    private country!: string;

    @Prop({
        type: Array,
        required: false,
        default: () => [],
    })
    private countries!: string[];

    get hasHolidayEvents(): boolean {
        const country = this.countries.length ? this.countries.join(',') : this.country;
        if (this.countries.length) {
            return this.eventsManagerService.hasHolidayEventsByDay({ day: this.day, country });
        }
        return this.eventsManagerService.hasHolidayEventsByDay({ day: this.day, country });
    }

    get hasLocalEvents(): boolean {
        const country = this.countries.length ? this.countries.join(',') : this.country;
        if (this.countries.length) {
            return this.eventsManagerService.hasLocalEventsByDay({ day: this.day, country }, { countries: this.countries });
        }
        return this.eventsManagerService.hasLocalEventsByDay({ day: this.day, country });
    }
}
