










import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import FleetFilterService, { FleetFilterServiceS } from '@/modules/cars/modules/fleet/fleet-filter.service';
import FleetService, { FleetServiceS } from '@/modules/cars/modules/fleet/fleet.service';

@Component({
    components: { CustomSelect },
})
export default class DataSourceFilter extends Vue {
    @Inject(FleetFilterServiceS) private fleetFiltersService!: FleetFilterService;
    @Inject(FleetServiceS) private fleetService!: FleetService;

    get options(): Item[] {
        const { availableDataSources } = this.fleetFiltersService;

        if (!availableDataSources) {
            return [];
        }
        const sources = availableDataSources.filter(item => item !== 'Brand');

        if (!this.currentValue || !sources.find(dataSource => dataSource === this.currentValue)) {
            const [defaultValue] = sources;
            this.currentValue = defaultValue;
        }

        return sources.map(value => ({
            name: value,
            value,
        }));
    }

    get currentValue() {
        return this.fleetService.storeState.settings.dataSource;
    }
    set currentValue(value) {
        if (value) {
            this.fleetService.storeState.settings.dataSource = value;
        }
    }
}
