










import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import FleetFilterService, { FleetFilterServiceS } from '@/modules/cars/modules/fleet/fleet-filter.service';
import FleetService, { FleetServiceS } from '@/modules/cars/modules/fleet/fleet.service';

@Component({
    components: { CustomSelect },
})
export default class PosFilter extends Vue {
    @Inject(FleetFilterServiceS) private fleetFiltersService!: FleetFilterService;
    @Inject(FleetServiceS) private fleetService!: FleetService;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;

    get items(): Item[] {
        const { availablePos } = this.fleetService;

        if (!availablePos) {
            return [];
        }

        if (!this.currentValue || !availablePos.find(pos => pos === this.currentValue)) {
            const [defaultPos] = availablePos;
            this.currentValue = defaultPos;
        }

        return availablePos.map(value => ({
            name: value,
            value,
        }));
    }

    get currentValue() {
        return this.fleetService.storeState.settings.pos;
    }
    set currentValue(value) {
        if (value) {
            this.carsSharedService.savePos(value);
        }
    }
}
