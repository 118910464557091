





















import { Component, Vue, Prop } from 'vue-property-decorator';
import Day from '@/modules/common/types/day.type';
import isDayType from '@/modules/common/component-validators/is-date.validator';
import EventTypesPoints from '@/modules/common/components/event-types-points.vue';
import { Inject } from 'inversify-props';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import { IResponsiveStyle } from '@/modules/cars/modules/fleet/fleet-settings.types';
import FleetService, { FleetServiceS } from '@/modules/cars/modules/fleet/fleet.service';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';

@Component({
    components: {
        EventTypesPoints,
    },
})
export default class FleetCalendarItemOutRange extends Vue {
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(FleetServiceS) private fleetService!: FleetService;
    @Inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;

    @Prop({
        required: true,
        type: Number,
        validator: isDayType,
    })
    day!: Day;

    @Prop({
        required: true,
        type: String,
    })
    private source!: string;

    @Prop({
        required: true,
    })
    private styles!: IResponsiveStyle;
}
