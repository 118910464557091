















import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import StyledSearchbar from '@/modules/common/components/styled-searchbar.vue';
import FleetFilterService, { FleetFilterServiceS } from '@/modules/cars/modules/fleet/fleet-filter.service';
import FleetService, { FleetServiceS } from '@/modules/cars/modules/fleet/fleet.service';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';

@Component({
    components: { CustomSelect, StyledSearchbar },
})
export default class PickUpCityFilter extends Vue {
    @Inject(FleetFilterServiceS) private fleetFiltersService!: FleetFilterService;
    @Inject(FleetServiceS) private fleetService!: FleetService;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;

    private query: string | null = null;

    get items(): Item[] {
        const { locations, countries } = this.fleetFiltersService.settings;
        const { country, pickUpCityCode } = this.fleetService.storeState.settings;

        if (!country || !countries || !locations) {
            return [];
        }

        let filteredLocations = this.fleetFiltersService.filterPickUpCitiesByCountry(country);

        if (!pickUpCityCode || !filteredLocations.find(location => location.locationId === pickUpCityCode)) {
            this.currentValue = filteredLocations.length ? filteredLocations[0].locationId : '';
        }

        if (this.query) {
            const searchPattern = new RegExp(this.query.toLowerCase());
            filteredLocations = filteredLocations.filter(location => searchPattern.test(location.locationName.toLowerCase()));
        }

        const result = filteredLocations.map(location => ({
            name: location.locationName,
            value: location.locationId,
            disabled: false,
        }));

        if (!result.length) {
            result.push({
                name: 'No results',
                value: 'No results',
                disabled: true,
            });
        }

        return result;
    }

    get currentValue() {
        const { locations } = this.fleetFiltersService.settings;
        const { pickUpCityCode } = this.fleetService.storeState.settings;

        if (!locations || !pickUpCityCode) {
            return null;
        }

        const currentLocation = locations.find(location => location.locationId === pickUpCityCode);

        if (!currentLocation) {
            return null;
        }

        return currentLocation.locationName;
    }
    set currentValue(value) {
        if (value) {
            this.carsSharedService.savePickUpCityAndCountry(value, this.$route.path);
        }
    }

    handleChange(query: string) {
        this.query = query;
    }
}
