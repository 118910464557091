









import { Component, Vue, Watch } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CustomMultiSelect, { Item } from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import FleetFilterService, { FleetFilterServiceS } from '@/modules/cars/modules/fleet/fleet-filter.service';
import FleetService, { FleetServiceS } from '@/modules/cars/modules/fleet/fleet.service';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import _ from 'lodash';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import { IOption } from '@/modules/cars/components/cars-filters/competitors-filter.vue';

@Component({
    components: { CustomMultiSelect },
})
export default class CompetitorsFilter extends Vue {
    @Inject(FleetFilterServiceS) private fleetFiltersService!: FleetFilterService;
    @Inject(FleetServiceS) private fleetService!: FleetService;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;
    @Inject(CarsServiceS) private carsService!: CarsService;
    private loadingNewFilters = false;

    get currentValue() {
        const { competitors } = this.fleetService.storeState.settings;
        const unselectedCompetitors = this.carsSharedService.getUnselectedCompetitors();

        if (!competitors) {
            return [];
        }

        return competitors.filter(item => !unselectedCompetitors.includes(item)).map(item => ({
            name: item,
            value: item,
        })) || [];
    }

    set currentValue(selectedItemValues) {
        const selectedValues = selectedItemValues.map(item => item.value);

        if (!this.loadingNewFilters) {
            const previouslySelectedCompetitorItems = this.currentValue;
            const previouslySelectedValues = previouslySelectedCompetitorItems.map(item => item.value);
            const newlySelectedCompetitors = _.difference(selectedValues, previouslySelectedValues);
            const newlyUnselectedCompetitors = _.difference(previouslySelectedValues, selectedValues);
            this.carsSharedService.updateUnselectedCompetitorsStore(newlySelectedCompetitors, 'unselect');
            this.carsSharedService.updateUnselectedCompetitorsStore(newlyUnselectedCompetitors, 'select');
            this.carsService.saveCompetitors(selectedValues);
            this.fleetService.storeState.settings.competitors = selectedValues;
        } else {
            const newlySelectedCompetitorItems = this.currentValue;
            const newlySelectedValues = newlySelectedCompetitorItems.map(item => item.value);
            this.carsService.saveCompetitors(newlySelectedValues);
            this.fleetService.storeState.settings.competitors = newlySelectedValues;
        }
    }

    get options() {
        return this.fleetFiltersService.competitorsFilter.map(val => ({
            value: val,
            name: val,
        })).sort((a, b) => {
            if (a.value < b.value) {
                return -1;
            }
            return 1;
        });
    }
}
