








import { Component, Vue, Prop } from 'vue-property-decorator';
import { IResponsiveStyle } from '@/modules/cars/modules/fleet/fleet-settings.types';

@Component
export default class FleetCalendarItemEmpty extends Vue {
    @Prop({
        required: true,
    })
    private styles!: IResponsiveStyle;
}
