

























import CalendarItemWrapper from '@/modules/common/components/ui-kit/calendar-item-wrapper.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import Day from '@/modules/common/types/day.type';
import CalendarEventsContainer from '@/modules/events/components/calendar-events-container.vue';
import EventTypesPoints from '@/modules/common/components/event-types-points.vue';
import FleetService, { FleetServiceS } from '@/modules/cars/modules/fleet/fleet.service';
import Occupancy from '@/modules/occupancy/components/occupancy.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import isDayType from '@/modules/common/component-validators/is-date.validator';
import MIPriceFilter from '@/modules/common/filters/mi-price.filter';
import { IResponsiveStyle } from '@/modules/cars/modules/fleet/fleet-settings.types';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';

interface ITableData {
    brokerName: string,
    total: number,
    myBroker: boolean,
}

@Component({
    filters: { MIPriceFilter },
    components: {
        CalendarEventsContainer,
        CalendarItemWrapper,
        Occupancy,
        EventTypesPoints,
    },
})
export default class FleetCalendarItem extends Vue {
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(FleetServiceS) private fleetService!: FleetService;
    @Inject(UserServiceS) userService!: UserService;
    @Inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;

    @Prop({
        required: true,
        type: Number,
        validator: isDayType,
    })
    private day!: Day;

    @Prop({
        required: true,
        type: String,
    })
    private source!: string;

    @Prop({
        required: true,
    })
    private styles!: IResponsiveStyle;

    myCardColor() {
        const { position, brokerCount } = this.positionData;
        const percentPosition: number = (position / brokerCount) * 100;
        return {
            high: position === 1,
            'med-high': percentPosition <= 50 && position !== 1,
            'med-low': percentPosition > 50 && position !== brokerCount,
            low: position === brokerCount && brokerCount !== 1,
        };
    }

    get number(): number | null {
        return this.fleetService.getNumberCar(this.day, this.source);
    }

    get positionData() {
        const data = this.fleetService.brokersDataByDay(this.day, this.source);
        let tableData: ITableData[] = [];
        if (!data) {
            return {
                brokerCount: 0,
                position: 0,
            };
        }

        tableData = data;

        tableData.sort((a, b) => b.total - a.total);

        return {
            brokerCount: tableData.length,
            position: tableData.findIndex(item => item.brokerName === this.userService.currentCompany) + 1,
        };
    }
}
