


















import { Component, Vue } from 'vue-property-decorator';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import FleetHeader from '@/modules/cars/modules/fleet/components/fleet-header.vue';
import FleetActions from '@/modules/cars/modules/fleet/components/fleet-actions.vue';
import FleetDensity from '@/modules/cars/modules/fleet/components/fleet-density.vue';
import FleetCalendarLabel from '@/modules/cars/modules/fleet/components/calendar/fleet-calendar-label.vue';

@Component({
    components: {
        PageWrapper,
        FleetHeader,
        FleetActions,
        FleetDensity,
        FleetCalendarLabel,
    },
})
export default class FleetDensityPage extends Vue {}
