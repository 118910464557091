import { render, staticRenderFns } from "./event-types-points.vue?vue&type=template&id=979a9562&scoped=true&"
import script from "./event-types-points.vue?vue&type=script&lang=ts&"
export * from "./event-types-points.vue?vue&type=script&lang=ts&"
import style0 from "./event-types-points.vue?vue&type=style&index=0&id=979a9562&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "979a9562",
  null
  
)

export default component.exports